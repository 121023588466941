import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Component } from 'vue';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/auth',
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'LoginPage',
        component: (): Promise<Component> => import('@/views/LoginPage.vue'),
      },
    ],
  },
  {
    path: '/modules',
    name: 'ModulesPage',
    component: (): Promise<Component> => import('@/views/ModulePage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory((import.meta.env || process.env).BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const firstPath = to.fullPath.split('/')[1];
  const user = localStorage.getItem('user');

  if (firstPath === 'logout') {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/auth/login';
  }

  if (firstPath === 'auth' || !firstPath) {
    if (user) {
      window.location.href = '/modules';
    } else if (!firstPath) {
      window.location.href = '/auth/login';
    }
  } else if (firstPath === 'modules') {
    if (!user) {
      window.location.href = '/auth/login';
    }
  }
});

export default router;
